import React from "react"
import styled from "styled-components"

import { InputContainer, Label, Input } from "src/components/TextField"
import ArrowIcon from "src/svg/arrow.svg"
import { MAILCHIMP } from "src/helpers/constants"

const Form = styled.form`
  text-align: center;
  margin: 50px;
  max-width: 370px;
  margin: 40px auto 20px;
`
const Title = styled.h3`
  margin-bottom: 10px;
`
const Submit = styled.button`
  padding: 0;
  margin: -2px 0 -1px;
  svg {
    display: block;
  }
`

const NewsletterForm: React.FC = () => (
  <Form method="post" action={MAILCHIMP}>
    <Title>Pour être au courant de nos prochaines commandes, inscrivez-vous à notre newsletter :</Title>
    <InputContainer>
      <Label htmlFor="newsletter">Courriel :</Label>
      <Input id="newsletter" type="email" name="EMAIL" />
      <Submit type="submit" aria-label="Valider">
        <ArrowIcon />
      </Submit>
    </InputContainer>
  </Form>
)

export default NewsletterForm
