import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Image from "gatsby-image/withIEPolyfill"

import Layout from "src/layout"
import Menu from "src/components/Menu"
import NewsletterForm from "src/components/NewsletterForm"
import { COLORS, EMAIL_ADDRESS, MAPS_URL, PHONE, TEL } from "src/helpers/constants"
import Column from "src/layout/column"

const Intro = styled.div`
  font-style: italic;
  a {
    white-space: nowrap;
  }
`
const Separator = styled.div`
  margin: 30px auto;
  padding: 0 20px;
  max-width: 300px;
  border-bottom: 1px solid ${COLORS.black};
`
const Address = styled.div`
  padding: 0 20px;
  font-weight: 500;
  font-size: 1.2em;
`
const Portraits = styled(Image)`
  margin: 0 auto;
  width: 60%;
  max-width: 300px;
`

interface DataType {
  portraits: { childImageSharp: Media }
}

const ContactPage: GatsbyPage<DataType> = ({ data }) => {
  return (
    <Layout title="Contact">
      <Menu />
      <Column $center>
        <h1>l’attrape miel</h1>
        <Intro>
          <p>
            L’attrape miel c’est nous, Cédric et Carine.
            <br />
            Nous nous sommes installés dans le Limousin aux portes du plateau de Millevaches en 2017.
          </p>
          <p>
            Apiculteur-récoltant, Cédric s’est formé successivement dans un rucher-école, au contact d’un vénérable
            apiculteur berrichon puis au sein de l’association de culture et d’apiculture urbaine{" "}
            <a href="https://assodardard.fr/">Dard-Dard</a>.
          </p>
          <p>
            Depuis son arrivée en Montagne Limousine, il profite des conseils d’une vaste communauté d’apiculteurs pour
            adapter ses pratiques au territoire.
          </p>
          <p>Carine est scénographe, elle accompagne le projet depuis ses débuts.</p>
          <p>
            C’est avec un grand plaisir que nous vous proposons nos miels de fleurs sauvages récoltés avec amour… et
            quelques piqûres !
          </p>
          <p>Carine et Cédric</p>
        </Intro>
        <Portraits fluid={data.portraits.childImageSharp.fluid} alt="Carint et Cédric" />
        <Separator />
        <Address>
          <p>
            Cédric Perrot
            <br />
            <a href={MAPS_URL}>
              Le bourg
              <br />
              23460 Saint-Martin-Château
            </a>
          </p>
          <p>
            <a href={TEL}>{PHONE}</a>
          </p>
          <p>
            <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
          </p>
        </Address>
        <Separator />
        <NewsletterForm />
      </Column>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    portraits: file(relativePath: { eq: "portraits.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 1) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
